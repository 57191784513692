import React from "react";
import CategoryButton from "../common/categoryButton";

interface PortfolioListProps {
  categories: Array<string>;
  selectedCate: string;

  setSelectedCate(cate: string): void;
}

const PortfolioList: React.FC<PortfolioListProps> = ({
  categories,
  selectedCate,
  setSelectedCate,
}) => {
  return (
    <div
      className={
        "relative mt-[40px] mb-[40px] flex flex-wrap justify-center gap-y-[8px] gap-x-[8px] p-[10px] xl:mt-0 xl:mb-0 xl:flex xl:gap-x-[21px] xl:pt-[102px] xl:pb-0"
      }
    >
      {categories.map((category, index) => (
        <CategoryButton
          key={index}
          clickHandler={setSelectedCate}
          isActive={selectedCate === category}
          text={category}
        />
      ))}
    </div>
  );
};

export default PortfolioList;
