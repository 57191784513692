import React, { useEffect, useState } from "react";
import HeroPoster from "../components/heroPoster/heroPoster";
import Layout from "../components/layout";
import TitleWithLine from "../components/common/TitleWithLine";
import PortfolioList from "../components/portfolio/PortfolioList";
import { ClientTypes } from "../components/heroPoster/TopProject";
import LeftCurve from "../components/svg/leftCurve";
import SeoDataHandler from "../helper/handler/seoData.handler";
import { SEO } from "../components/seo";
import ProjectList from "../components/portfolio/projectList";

interface PortfolioProps {
  projects: Array<ClientTypes>;
}

const Portfolio: React.FC<any> = ({ pageContext }) => {
  const [clientAllData, setClientAllData] = useState<any[]>([]);
  const [clientData, setClientData] = useState<any>([]);
  const [tagData, setTagData] = useState<any>([]);
  const [selectedCate, setSelectedCate] = useState<string>("All");

  useEffect(() => {
    setTagData(pageContext.tagData);
    setClientAllData(pageContext.clientData);
    setClientData(pageContext.clientData);
  }, []);

  useEffect(() => {
    if (selectedCate !== "All") {
      const filterData = clientAllData.filter((project) => {
        const isIncluded = project?.attributes?.tags?.data.find(
          (tag: any) => tag?.attributes?.name === selectedCate
        );
        if (isIncluded) {
          return true;
        }
      });
      setClientData(filterData);
    } else {
      setClientData(clientAllData);
    }
  }, [selectedCate, clientAllData]);

  return (
    <Layout
      footerData={pageContext?.common?.footerData}
      navbarData={pageContext?.common?.navbarData}
    >
      <div
        className={
          "-z-1 absolute left-0 hidden lg:top-[-89px] lg:block xl:top-[85px]"
        }
      >
        <LeftCurve />
      </div>
      <HeroPoster
        pageData={pageContext?.portFolioPageData?.pageData}
        coverUrl={pageContext?.portFolioPageData?.pageMedia}
      />
      <TitleWithLine
        primaryTitle={'"Our ultimate experiences with'}
        secondaryTitle={'story, emotion, and Purpose"'}
      />
      <PortfolioList
        categories={["All", ...tagData]}
        selectedCate={selectedCate}
        setSelectedCate={setSelectedCate}
      />
      {clientData?.length > 0 &&
        clientData.map((project: any, index: number) => (
          <ProjectList
            key={index}
            {...project}
            invert={index % 2}
            showLabel={true}
          />
        ))}
    </Layout>
  );
};

export default Portfolio;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.portFolioPageData?.pageData);
  return (
    <SEO
      title={seoData.title}
      description={seoData.description}
      keywords={seoData.keywords}
      image={seoData.image}
      pathname={seoData.pagePath}
    />
  );
};
