import React, { FC } from "react";
import Project from "../heroPoster/Project";
import classNames from "classnames";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

const ProjectList: FC<any> = (client) => {
  return (
    <div
      className={classNames(
        "mt-[30px] px-0 lg:flex lg:pb-[20px] 2xl:px-[100px]",
        {
          "bg-hero-gradient-start": client.invert % 2,
        }
      )}
    >
      <SecondaryWidthWrapper>
        <Project
          key={client.key}
          {...client}
          invert={client.invert % 2}
          showLabel={client.showLabel}
        />
      </SecondaryWidthWrapper>
    </div>
  );
};

export default ProjectList;
